.Terms {
  padding: 32px;
}

.Terms h2 {
  font-size: calc(32px + 2vmin);
  color: black;
  font-family: "Fredoka", sans-serif;
  margin: 32px 0px;
  margin-bottom: 32px;
}

.Terms h3 {
  font-size: calc(1px + 2vmin);
  color: black;
  margin-bottom: 32px;
}

.Terms p,
ul,
ol,
li,
a {
  font-size: calc(6px + 1.5vmin);
  color: black;
  /* font-family: "Fredoka", sans-serif; */
  margin-bottom: 16px;
  margin-left: 16px;
}
