.Guidelines {
  padding: 32px;
}

.Guidelines h2 {
  font-size: calc(32px + 2vmin);
  color: black;
  font-family: "Fredoka", sans-serif;
  margin: 32px 0px;
  margin-bottom: 32px;
}

.Guidelines h3 {
  font-size: calc(1px + 2vmin);
  color: black;
  margin-bottom: 32px;
}

.Guidelines p,
ul,
li,
a {
  font-size: calc(6px + 1.5vmin);
  color: black;
  /* font-family: "Fredoka", sans-serif; */
  margin-bottom: 16px;
}

.Guidelines .link {
  padding-left: 10px;
}
