.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

/* Split the screen in half */
.split {
  /* height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Control the left side */
.left {
  /* left: 0; */
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  /* margin-top: 45%vh; */
}

/* Control the right side */
.right {
  /* right: 0; */
  background-color: black;
}

/* If you want the content centered horizontally and vertically */
.centered {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  text-align: center;
  align-self: center;
}

/* Style the image inside the centered container, if needed */
.threefourth img {
  width: 150px;
  /* border-radius: 50%; */
}

.App-header h2 {
  /* background-color: #282c34; */
  /* min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(48px + 2vmin);
  color: black;
  font-family: "Fredoka", sans-serif;
  margin-bottom: 8px;
}

.App-header h3 {
  /* background-color: #282c34; */
  /* min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  opacity: 0.5;
  font-size: calc(6px + 2vmin);
  color: black;
}

.link {
  font-size: calc(4px + 1vmin);
  padding: 10px;
  color: black;
}

a:link {
  text-decoration: none;
}
/* 
@media (max-width: 600px) {
  .split {
    flex-direction: column;
  }

  .threefourth {
    margin-top: 2rem;
  }
} */

/* Mobile styles */
@media (max-width: 768px) {
  .App {
    flex-direction: column;
    height: 200vh;
  }

  .threefourth {
    margin-top: 2rem;
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 55vh; /* Adjust the height as needed */
}

.threefourth {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 15vh; /* Adjust the height as needed */
}

.terms {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 25vh; /* Adjust the height as needed */
}
