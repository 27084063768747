.About {
  padding: 32px;
}

.About h2 {
  font-size: calc(32px + 2vmin);
  color: black;
  font-family: "Fredoka", sans-serif;
  margin: 32px 0px;
  margin-bottom: 32px;
}

.About h3 {
  font-size: calc(1px + 2vmin);
  color: black;
  margin-bottom: 32px;
}

.About p,
ul,
li,
a {
  font-size: calc(6px + 1.5vmin);
  color: black;
  /* font-family: "Fredoka", sans-serif; */
  margin-bottom: 16px;
}
